import myLottoOnline from '../../apps/mylottoonline/src/images/logo.svg';
import lottoOnline24 from '../../apps/lottoonline24/src/images/logo.svg';

const getLogoForCurrentSite = () => {
    switch (process.env.GATSBY_SITE_NAME) {
        case 'mylottoonline':
            return myLottoOnline;
        case 'lottoonline24':
            return lottoOnline24;
        default:
            return null;
    }
};

const logoSrc = getLogoForCurrentSite();

export default logoSrc;
