import React from "react";
import Helmet from "react-helmet";
import Main from "../Main";
import Header from "../Header";
import Footer from "../Footer";
import Logo from "../Logo";
import logoSrc from "../../helpers/logos";

const Layout = ({ children, customClass = '', mainCustomClass = '', isDisabledMetaIndex = false}) => (
    <div className={`layout ${customClass}`}>
        {
            isDisabledMetaIndex &&
            <Helmet>
                <meta name="robots" content="noindex, nofollow"/>
            </Helmet>
        }
        <Header>
            {logoSrc && <Logo src={logoSrc}/>}
        </Header>
        <Main customClass={mainCustomClass}>{children}</Main>
        <Footer>
            <p>This site supports the <a href="https://responsiblegambling.org.za/" target='_blank' rel='nofollow'>National Responsible Gambling Programme.</a></p>
            <p>National gambling toll free counselling line: <a href="tel:0800006008">0800 006 008</a>.</p>
            <p>Website content is only for 18+</p>
        </Footer>
    </div>
);

export default Layout;
