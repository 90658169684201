import React from "react";
import { Link } from "gatsby";

const Logo = ({ src }) => (
    <Link to={'/'} className='logo'>
        <img src={src} alt="site logo"/>
    </Link>
);

export default Logo;
